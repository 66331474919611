import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetch } from './fetch.hook';
import { Page } from '../types';
import { GetContentPostDto, MerchantContentProfile, UpdateMerchantContentProfileDto } from '../models/content';

const Routes = {
  merchantPosts: (
    merchantId: number,
    page: number,
    pageSize: number,
    approvedByMerchant: boolean,
  ) => `/merchants/${merchantId}/posts?approvedByMerchant=${approvedByMerchant}&page=${page}&pageSize=${pageSize}`,
  post: (
    postId: number,
  ) => `/posts/${postId}`,
  merchantPost: (
    merchantId: number,
    postId: number,
  ) => `/merchants/${merchantId}/posts/${postId}`,
  merchantPostAccept: (
    merchantId: number,
    postId: number,
  ) => `/merchants/${merchantId}/posts/${postId}/accept`,
  merchantPostDeny: (
    merchantId: number,
    postId: number,
  ) => `/merchants/${merchantId}/posts/${postId}/deny`,
  merchantContentProfile: (
    merchantId: number,
  ) => `/merchants/${merchantId}/content-profile`,
  merchantContentMedia: (
    merchantId: number,
  ) => `/merchants/${merchantId}/upload`,
};

export default function useContent() {
  const { fetchJsonFunc, executeFetchFunc } = useFetch();

  const useMerchantPostsAcceptMutation = (
    merchantId: number,
    onSuccess: () => void,
  ) => {
    return useMutation({
      mutationFn: (postId: number) => executeFetchFunc(
        Routes.merchantPostAccept(merchantId, postId), { method: 'POST' }),
      onSuccess,
    });
  };

  const useMerchantPostsDenyMutation = (
    merchantId: number,
    onSuccess: () => void,
  ) => {
    return useMutation({
      mutationFn: (postId: number) => executeFetchFunc(
        Routes.merchantPostDeny(merchantId, postId), { method: 'POST' }),
      onSuccess,
    });
  };

  const useMerchantPostsDeleteMutation = (
    merchantId: number,
    onSuccess: () => void,
  ) => {
    return useMutation({
      mutationFn: (postId: number) => executeFetchFunc(
        Routes.merchantPost(merchantId, postId), { method: 'DELETE' }),
      onSuccess,
    });
  };

  const useMerchantPostsQuery = (
    merchantId: number,
    page = 0,
    pageSize = 10,
    approvedByMerchant = true,
  ) => {
    return useQuery({
      queryKey: ['merchantPosts', page, pageSize, approvedByMerchant, merchantId],
      queryFn: () => fetchJsonFunc<Page<GetContentPostDto>>(
        Routes.merchantPosts(merchantId, page, pageSize, approvedByMerchant)),
    });
  };

  const useUpdateMerchantContentProfileMutation = (
    merchantId: number,
    onSuccess: () => void,
    onError: (error: any) => void
  ) => {
    return useMutation({
      mutationFn: (request: UpdateMerchantContentProfileDto) => executeFetchFunc(
        Routes.merchantContentProfile(merchantId), { method: 'PUT', body: JSON.stringify(request) }),
      onSuccess,
      onError
    });
  };

  const useMerchantContentProfileQuery = (
    merchantId: number
  ) => {
    return useQuery({
      queryKey: ['merchantContentProfile', merchantId],
      queryFn: () => fetchJsonFunc<MerchantContentProfile>(
        Routes.merchantContentProfile(merchantId)),
    });
  };

  const useUploadMerchantMediaMutation = (
    merchantId: number,
    onSuccess: (response: { url: string }) => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (file: File) =>
        fetchJsonFunc<{url: string}>(Routes.merchantContentMedia(merchantId), {
          method: 'POST',
          body: JSON.stringify({
            mimeType: file.type,
          }),
        }).then(async(response) => {
          const res = await fetch(response.url, {
            method: 'PUT',
            body: file as any,
          });
          if (!res.ok) {
            const error = await res.json();
            console.log(error);
            throw new Error('Failed to upload media');
          }
          return { url: response.url.split('?')[0] };
        }),
      onSuccess,
      onError,
    });
  }

  const useMerchantPostQuery = (merchantId: number, postId: number) => {
    return useQuery({
      queryKey: ['merchantPost', merchantId, postId],
      queryFn: () => fetchJsonFunc<GetContentPostDto>(
        Routes.post(postId)
      ),
      enabled: !!postId,
    });
  };

  return {
    useMerchantPostsQuery,
    useMerchantPostsAcceptMutation,
    useMerchantPostsDeleteMutation,
    useMerchantPostsDenyMutation,
    useMerchantContentProfileQuery,
    useUpdateMerchantContentProfileMutation,
    useUploadMerchantMediaMutation,
    useMerchantPostQuery,
  };
}