import { useEffect, useState } from "react";
import BasicForm, { FormField } from "../../../common/library/BasicForm";
import { Merchant } from "../../../models/merchant";
import Spinner from "../../../common/library/Spinner";
import { buildServerPhoneNumber, buildVisualPhoneNumber } from "../../../util";
import {
  ChatIcon,
  GlobeIcon,
  IdentificationIcon,
  KeyIcon,
  MailIcon,
  PhoneIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/react/outline";
import Input from "../../../common/library/Input";
import Button from "../../../common/library/Button";
import { BookOpenIcon } from "@heroicons/react/solid";
import ImageCircleUpload from "../../../common/library/ImageCircleUpload/ImageCircleUpload";
import CoverImageUpload from "../../../common/library/CoverImageUpload.tsx/CoverImageUpload";
import AddCustomLinkButton from "../../../common/library/AddCustomLinkButton";
import useAuthContext from "../../../hooks/auth-context.hook";
import useMerchants from "../../../hooks/merchants.hook";
import useMerchantCustomLinks from "../../../hooks/merchants-custom-links.hook";
import GenerateGoogleReviewLink from "./GenerateGoogleReviewLink";
import { toast } from "react-toastify";
import useContent from "../../../hooks/content.hook";

export default function MerchantProfileGeneral() {
  const auth = useAuthContext();
  const { user, merchant: activeMerchant } = auth.getMerchantAndUser();

  const {
    useUpdateMerchantMutation,
    useMerchantIndustriesQuery,
    useMerchantQuery,
  } = useMerchants();
  const { data: merchant, refetch: refetchMerchant } = useMerchantQuery(
    activeMerchant.id,
    activeMerchant
  );
  const updateMerchant = useUpdateMerchantMutation(
    activeMerchant.id,
    (res) => {
      refetchMerchant();
      auth.updateMerchant(res);
      toast.success("Updated merchant");
    },
    () => {
      toast.error("Failed to update merchant");
    }
  );
  const { data: industries } = useMerchantIndustriesQuery();

  // Custom Links
  const { useCreateCustomLinkMutation, useDeleteCustomLinkMutation } =
    useMerchantCustomLinks();
  const createCustomLink = useCreateCustomLinkMutation(
    activeMerchant.id,
    () => {
      refetchMerchant();
      toast.success("Custom Link created");
    },
    (error) => toast.error(error.message[0])
  );
  const deleteCustomLink = useDeleteCustomLinkMutation(
    activeMerchant.id,
    () => {
      refetchMerchant();
      toast.info("Custom Link deleted");
    },
    (error) => toast.error(error.message[0])
  );

  const { useUploadMerchantMediaMutation } = useContent();
  const [currentLabel, setCurrentLabel] = useState<string>(""); // State to store the current label

  const uploadMedia = useUploadMerchantMediaMutation(
    activeMerchant.id,
    (response) => {
      // Use the uploaded file URL to create a custom link
      if (currentLabel) {
        createCustomLink.mutate({ label: currentLabel, url: response.url });
      } else {
        toast.error("Label should not be empty");
      }
    },
    (error) => {
      toast.error("Failed to upload media");
    }
  );

  const handleCreateCustomLink = (label: string, url: string, file?: File) => {
    setCurrentLabel(label); // Store the label in state
    if (file) {
      uploadMedia.mutate(file);
    } else {
      if (label) {
        createCustomLink.mutate({ label, url });
      } else {
        toast.error("Label should not be empty");
      }
    }
  };

  const uploadLogo = useUploadMerchantMediaMutation(
    activeMerchant.id,
    (res) => {
      merchant && updateMerchant.mutate({ ...merchant, logoImageUrl: res.url });
      toast.success("Logo upload complete");
    },
    (error) => {
      toast.error(error.message[0]);
    }
  );
  const uploadCover = useUploadMerchantMediaMutation(
    activeMerchant.id,
    (res) => {
      merchant &&
        updateMerchant.mutate({ ...merchant, coverImageUrl: res.url });
      toast.success("Cover upload complete");
    },
    (error) => {
      toast.error(error.message[0]);
    }
  );

  const [editingMerchant, setEditingMerchant] = useState<Merchant | undefined>(
    merchant
  );
  const [reviewOpen, setReviewOpen] = useState<boolean>(false);

  useEffect(() => {
    merchant && auth.updateMerchant(merchant);
    merchant && setEditingMerchant(merchant);
  }, [merchant, auth]);

  const saveImage = async (blob: Blob, type: "cover" | "logo") => {
    if (merchant && user) {
      const file = new File([blob], "image.jpg", { type: blob.type });
      type === "logo" ? uploadLogo.mutate(file) : uploadCover.mutate(file);
    }
  };

  const fields: FormField[] = [
    {
      label: "Name",
      icon: <UserIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.name,
      onValueChange: (name) =>
        editingMerchant && setEditingMerchant({ ...editingMerchant, name }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        merchant?.name !== editingMerchant.name &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Industry",
      icon: <BookOpenIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.industry,
      combobox: true,
      options: industries,
      onValueChange: (industry) => {
        if (editingMerchant) {
          updateMerchant.mutate({
            ...editingMerchant,
            industry,
            phoneNumber:
              editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
                ? buildServerPhoneNumber(editingMerchant.phoneNumber)
                : undefined,
          });
        }
      },
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Description",
      icon: <ChatIcon height={16} width={16} />,
      onValueChange: (description) =>
        editingMerchant &&
        setEditingMerchant({
          ...editingMerchant,
          description,
        }),
      value: editingMerchant && editingMerchant.description,
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Email",
      icon: <MailIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.email,
      onValueChange: (email) =>
        editingMerchant && setEditingMerchant({ ...editingMerchant, email }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.email !== merchant?.email &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Phone Number",
      icon: <PhoneIcon height={16} width={16} />,
      value:
        editingMerchant && buildVisualPhoneNumber(editingMerchant.phoneNumber!),
      onValueChange: (phoneNumber) => {
        if (editingMerchant) {
          setEditingMerchant({
            ...editingMerchant,
            phoneNumber: buildVisualPhoneNumber(phoneNumber),
          });
        }
      },
      inlineEditing: true,
      onSave: () => {
        if (editingMerchant) {
          const serverPhoneNumber = editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
            ? buildServerPhoneNumber(editingMerchant.phoneNumber)
            : undefined;

          if (serverPhoneNumber !== merchant?.phoneNumber) {
            updateMerchant.mutate({
              ...editingMerchant,
              phoneNumber: serverPhoneNumber,
            });
          }
        }
      },
    },
    {
      label: "Website",
      icon: <GlobeIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.websiteUrl,
      onValueChange: (websiteUrl) =>
        editingMerchant &&
        setEditingMerchant({ ...editingMerchant, websiteUrl }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.websiteUrl !== merchant?.websiteUrl &&
        updateMerchant.mutate({
          ...editingMerchant,
          websiteUrl: editingMerchant.websiteUrl,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Facebook Page Username",
      icon: <TagIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.facebookHandle,
      onValueChange: (facebookHandle) =>
        editingMerchant &&
        setEditingMerchant({ ...editingMerchant, facebookHandle }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.facebookHandle !== merchant?.facebookHandle &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Facebook Place ID (For Auto Tagging Facebook Shares)",
      icon: <IdentificationIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.facebookPlaceId,
      onValueChange: (facebookPlaceId) =>
        editingMerchant &&
        setEditingMerchant({ ...editingMerchant, facebookPlaceId }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "Instagram Username",
      icon: <TagIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.instagramHandle,
      onValueChange: (instagramHandle) =>
        editingMerchant &&
        setEditingMerchant({ ...editingMerchant, instagramHandle }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.instagramHandle !== merchant?.instagramHandle &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "TikTok Username",
      icon: <TagIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.tiktokHandle,
      onValueChange: (tiktokHandle) =>
        editingMerchant &&
        setEditingMerchant({ ...editingMerchant, tiktokHandle }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.tiktokHandle !== merchant?.tiktokHandle &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
    {
      label: "X Username",
      icon: <TagIcon height={16} width={16} />,
      value: editingMerchant && editingMerchant.xHandle,
      onValueChange: (xHandle) =>
        editingMerchant && setEditingMerchant({ ...editingMerchant, xHandle }),
      inlineEditing: true,
      onSave: () =>
        editingMerchant &&
        editingMerchant.xHandle !== merchant?.xHandle &&
        updateMerchant.mutate({
          ...editingMerchant,
          phoneNumber:
            editingMerchant.phoneNumber && editingMerchant.phoneNumber !== ""
              ? buildServerPhoneNumber(editingMerchant.phoneNumber)
              : undefined,
        }),
    },
  ];

  return (
    <>
      <GenerateGoogleReviewLink
        open={reviewOpen}
        onFinished={(link: string) => {
          setReviewOpen(false);
          editingMerchant &&
            updateMerchant.mutate({
              ...editingMerchant,
              googleReviewUrl: link,
            });
        }}
        onClose={() => setReviewOpen(false)}
      />
      {editingMerchant ? (
        <div className="w-full">
          <div className="pb-2">
            <div className="relative">
              {/* Cover Image Section */}
              <div className="w-full h-[200px] md:h-[300px] rounded-xl overflow-hidden shadow-lg">
                <CoverImageUpload
                  saving={uploadCover.status === "pending"}
                  imageUrl={
                    activeMerchant.coverImageUrl ||
                    "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  }
                  onSave={(file) => saveImage(file, "cover")}
                />
              </div>

              {/* Logo Section - Positioned over cover image */}
              <div className="absolute left-4 md:left-8 -bottom-12 md:-bottom-16">
                <div className="relative w-24 h-24 md:w-32 md:h-32">
                  <ImageCircleUpload
                    saving={uploadLogo.status === "pending"}
                    imageUrl={
                      activeMerchant.logoImageUrl ||
                      "https://prod-werehere-images.s3.amazonaws.com/QBON.png"
                    }
                    onSave={(file) => saveImage(file, "logo")}
                  />
                </div>
              </div>
            </div>

            <div className="pt-24">
              <h1 className="font-bold">General Information </h1>
            </div>
            <div className="w-full max-w-[550px]">
              <BasicForm fields={fields} />
            </div>

            <div className="w-full max-w-[550px] pt-2">
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                <div className="w-full sm:flex-grow">
                  <Input
                    label="Google Review Link"
                    value={editingMerchant?.googleReviewUrl}
                  />
                </div>
                <div className="w-full sm:w-auto sm:pt-6">
                  <Button
                    label="Generate"
                    onClick={() => setReviewOpen(true)}
                    icon={<KeyIcon />}
                    className="w-full sm:w-auto"
                  />
                </div>
              </div>
            </div>

            <div className="pt-6">
              <h1 className="font-bold">Custom Links</h1>
            </div>

            <div className="w-full max-w-[550px]">
              {editingMerchant.customLinks &&
                editingMerchant.customLinks.length > 0 && (
                  <div className="space-y-2">
                    {editingMerchant.customLinks.map((link, index) => (
                      <Input
                        key={index}
                        value={link.url}
                        label={link.label}
                        deletable
                        onDelete={() => deleteCustomLink.mutate(link.id)}
                      />
                    ))}
                  </div>
                )}

              <div className="pt-6">
                <AddCustomLinkButton onCreate={handleCreateCustomLink} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <Spinner width={8} height={8} color="red" />
        </div>
      )}
    </>
  );
}
