import { useState } from 'react';
import Button from '../../common/library/Button';
import { ErrorMessage } from '../../common/library/ErrorMessage';
import Card from '../../common/library/Card';
import { useUsers } from '../../hooks/users.hook';
import useAuthContext from '../../hooks/auth-context.hook';

export default function TermsAndConditions() {
  const context = useAuthContext();
  const { user } = useAuthContext().getUser();
  const [isChecked, setIsChecked] = useState(false);

  const { useUpdateUserMutation } = useUsers();
  const updateUser = useUpdateUserMutation(user.id, () => {
    setErrorMessage(undefined);
    context.refreshUserSession();
  }, () => setErrorMessage('An error occurred with the server.'));
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <>
      <div className="relative min-h-screen flex flex-col sm:justify-center items-center bg-gray-100">
        <div className="relative sm:max-w-md w-full align-middle">
          <div
            className="relative w-full sm:h-full h-screen rounded-3xl px-6 py-4 bg-white shadow-md m-auto flex sm:justify-center items-center">
            <Card className="w-[500px] m-auto">
              <div className="m-auto w-full">
                <label className="block mt-3 text-lg text-gray-700 text-center font-semibold">
                  Terms of Use and Privacy Policy
                </label>
                <div className="p-2">
                  {errorMessage && <ErrorMessage message={[errorMessage]} />}
                </div>

                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className="w-4 h-4"
                  />
                  <span>
                    I agree to the{' '}
                    <span>
                      <a
                        href="https://qbonapp.com/privacy-policy-2/"
                        className="underline text-blue-500"
                      >
                        privacy policy
                      </a>
                    </span>{' '}
                    and{' '}
                    <a
                      href="https://qbonapp.com/terms-of-use/"
                      className="underline text-blue-500"
                    >
                      terms of use
                    </a>
                    .
                  </span>
                </div>

                <div className="mt-7 mb-5">
                  <Button
                    loading={updateUser.status === 'pending'}
                    label="Submit"
                    buttonWidth="full"
                    disabled={!isChecked}
                    onClick={() => updateUser.mutate({
                      ...user,
                      acceptedTermsAndConditions: true,
                    })}
                  />
                </div>
                <div className="mt-3 mb-5">
                  <Button
                    label="Back to Login"
                    buttonWidth="full"
                    variant="secondary"
                    onClick={() => {
                      context?.logOut();
                    }}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
