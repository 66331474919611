import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import MerchantDashboard from "./pages/merchant-dashboard/MerchantDashboard";
import MerchantProfilePage from "./pages/merchant-dashboard/merchant-profile/MerchantProfilePage";
import { useEffect } from "react";
import { Home } from "./pages/home/Home";
import MerchantCoupons from "./pages/merchant-dashboard/merchant-coupons/MerchantCoupons";
import MerchantQRCode from "./pages/merchant-dashboard/merchant-qr-code/MerchantQRCode";
import MerchantUsersPage from "./pages/merchant-dashboard/merchant-users/MerchantUsersPage";
import MerchantMetricsPage from "./pages/merchant-dashboard/merchant-metrics/MerchantMetrics";
import MerchantSubscriptionPage from "./pages/merchant-dashboard/merchant-subscription/MerchantSubscriptionPage";
import TermsAndConditions from "./pages/home/TermsAndConditions";
import CreateQbon from "./pages/merchant-dashboard/merchant-coupons/CreateQbon";
import MerchantScannerPage from "./pages/merchant-dashboard/merchant-scanner/MerchantScannerPage";
import MerchantScannerRedeem from "./pages/merchant-dashboard/merchant-scanner/MerchantScannerRedeem";
import MerchantRaffles from "./pages/merchant-dashboard/merchant-raffles/RafflesPage";
import MerchantContentPage from "./pages/merchant-dashboard/merchant-content/MerchantContentPage";
import CreateRafflePage from "./pages/merchant-dashboard/merchant-raffles/CreateRafflePage";
import RaffleDetailsPage from "./pages/merchant-dashboard/merchant-raffles/RaffleDetailsPage";
import CreateMerchantPage from "./pages/home/CreateMerchantPage";
import useAuthContext from "./hooks/auth-context.hook";
import AdminDashboard from "./pages/admin-dashboard/AdminDashboard";
import { SystemMetrics } from "./pages/admin-dashboard/SystemMetrics";
import Devices from "./pages/admin-dashboard/Devices";
import TutorialPage from "./pages/merchant-dashboard/tutorial/TutorialPage";
import MerchantSingleCoupon from "./pages/merchant-dashboard/merchant-coupons/MerchantSingleCoupon";
import UserInvites from "./pages/user/UserInvites";
import Merchants from "./pages/home/Merchants";
import MerchantDevicesPage from "./pages/merchant-dashboard/merchant-qr-code/MerchantDevicesPage";
import Login from "./pages/login/Login";
import SignUp from "./pages/login/SignUp";
import ForgotPassword from "./pages/login/ForgotPassword";
import NewPassword from "./pages/login/NewPassword";
import NewPasswordComplete from "./pages/login/NewPasswordComplete";
import VerifyEmail from "./pages/login/VerifyEmail";
import Spinner from "./common/library/Spinner";

const LoginRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="new-password-complete" element={<NewPasswordComplete />} />
        <Route path="verify" element={<VerifyEmail />} />
        <Route path="*" element={<Navigate to="login" replace />} />
      </Routes>
    </>
  );
};

const HomeRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthContext();
  const user = auth.getUser().user;

  useEffect(() => {
    if (
      (location.pathname.includes("terms-and-conditions") &&
        user &&
        user.acceptedTermsAndConditions === null) ||
      (user && !user.acceptedTermsAndConditions)
    ) {
      navigate("terms-and-conditions");
    } else if (location.pathname.includes("terms-and-conditions")) {
      navigate("home/merchants");
    }
  }, [user, navigate, location.pathname]);

  return (
    <>
      <Routes>
        {user?.type === "SUPER_ADMIN" && (
          <Route path="admin" element={<AdminDashboard />}>
            <Route path="users" element={<></>} />
            <Route path="devices" element={<Devices />} />
            <Route path="system-metrics" element={<SystemMetrics />} />
          </Route>
        )}
        <Route path="home" element={<Home />}>
          <Route path="merchants" element={<Merchants />} />
          <Route path="invites" element={<UserInvites />} />
        </Route>
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="create-merchant" element={<CreateMerchantPage />} />
        <Route path="*" element={<Navigate to="home/merchants" replace />} />
      </Routes>
    </>
  );
};

const MerchantRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="dashboard" element={<MerchantDashboard />}>
          <Route path="profile" element={<MerchantProfilePage />} />
          <Route path="qr-code" element={<MerchantQRCode />} />
          <Route path="devices" element={<MerchantDevicesPage />} />
          <Route path="qbons" element={<MerchantCoupons />} />
          <Route path="qbons/:couponId" element={<MerchantSingleCoupon />} />
          <Route path="giveaways" element={<MerchantRaffles />} />
          <Route path="giveaways/:raffleId" element={<RaffleDetailsPage />} />
          <Route path="giveaways/create" element={<CreateRafflePage />} />
          <Route path="qbons/create" element={<CreateQbon />} />
          <Route path="merchant-users" element={<MerchantUsersPage />} />
          <Route path="invites" element={<UserInvites />} />
          <Route path="scanner" element={<MerchantScannerPage />} />
          <Route path="scanner/redeem" element={<MerchantScannerRedeem />} />
          <Route path="metrics" element={<MerchantMetricsPage />} />
          <Route path="content" element={<MerchantContentPage />} />
          <Route path="subscription" element={<MerchantSubscriptionPage />} />
        </Route>
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="tutorial" element={<TutorialPage />} />
        <Route path="landing" element={<MerchantProfilePage />} />
        <Route path="*" element={<Navigate to="dashboard/profile" replace />} />
      </Routes>
    </>
  );
};

function Router() {
  const { session, isSessionLoading, merchant } = useAuthContext();

  if (isSessionLoading) {
    return (
      <div className="fixed inset-0 w-full h-full flex items-center justify-center bg-white">
        <Spinner height={10} width={10} color="red" />
      </div>
    );
  }

  return (
    <>
      {session === null ? (
        <LoginRoutes />
      ) : (
        <>{merchant !== undefined ? <MerchantRoutes /> : <HomeRoutes />}</>
      )}
    </>
  );
}

export default Router;
