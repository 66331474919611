import { useState } from "react";
import Input from "./Input";
import Button from "./Button";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { LinkIcon, UploadIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

interface Props {
  onCreate: (label: string, url: string, file?: File) => void;
}

function AddCustomLinkButton({ onCreate }: Props) {
  const [label, setLabel] = useState("");
  const [url, setUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleSubmit = () => {
    if (label) {
      onCreate(label, url, file ?? undefined);
      resetForm();
    }
  };

  const resetForm = () => {
    setLabel("");
    setUrl("");
    setFile(null);
    setIsEditing(false);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <>
        {!isEditing ? (
          <motion.div
            key="add-button"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.1 }}
          >
            <Button
              onClick={() => setIsEditing(true)}
              label="Add Custom Link"
              icon={<LinkIcon className="h-4 w-4" />}
              buttonWidth="full"
            />
          </motion.div>
        ) : (
          <motion.div
            key="form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.1 }}
            className="flex flex-col sm:flex-row gap-3"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleFileDrop}
          >
            <div className="flex-1 flex flex-col gap-3">
              <Input
                type="text"
                placeholder="Label"
                value={label}
                onChange={setLabel}
              />
              <Input
                type="text"
                placeholder="URL"
                value={url}
                onChange={setUrl}
                disabled={!!file}
              />
              <div
                className={`flex items-center justify-center border-dashed border-2 p-4 rounded-lg transition-colors ${
                  isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
                }`}
              >
                <input
                  type="file"
                  className="hidden"
                  id="file-upload"
                  onChange={handleFileSelect}
                />
                <label
                  htmlFor="file-upload"
                  className="flex flex-col items-center cursor-pointer"
                >
                  <UploadIcon className="h-8 w-8 text-gray-500" />
                  <span className="text-sm text-gray-500">
                    {file
                      ? file.name
                      : "Drag & drop a file here or click to select"}
                  </span>
                </label>
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                onClick={handleSubmit}
                className="text-white bg-green-500 hover:bg-green-600"
                icon={<CheckIcon className="h-4 w-4" />}
                aria-label="Save"
              />
              <Button
                onClick={resetForm}
                className="text-white bg-red-500 hover:bg-red-600"
                icon={<XIcon className="h-4 w-4" />}
                aria-label="Cancel"
              />
            </div>
          </motion.div>
        )}
    </>
  );
}

export default AddCustomLinkButton;
