import { useState } from 'react';
import { PageInfo } from '../../../common/library/Pagination';
import Table, { TableColumn } from '../../../common/library/Table';
import { Modal } from '../../../common/library/Modal';
import Button from '../../../common/library/Button';
import { ErrorMessage } from '../../../common/library/ErrorMessage';
import { MerchantUser } from '../../../models/merchant';
import useAuthContext from '../../../hooks/auth-context.hook';
import useMerchants from '../../../hooks/merchants.hook';
import useMerchantUsers from '../../../hooks/merchant-users.hook';
import { toast } from 'react-toastify';

export default function MerchantUsers() {
  const { merchant, user } = useAuthContext().getMerchantAndUser();
  const { useMerchantUsersQuery, useDeleteMerchantUserMutation } = useMerchants();
  const { useTransferOwnershipMutation } = useMerchantUsers();

  const [userToDelete, setUserToDelete] = useState<MerchantUser>();
  const [deleteError, setDeleteError] = useState<string>();
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    pageSize: number;
  }>({
    pageNumber: 0,
    pageSize: 10,
  });

  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const {
    data: merchantUsers, refetch: refetchMerchantUsers, isLoading: merchantUsersLoading,
    isSuccess: isSuccessMerchantUsers,
  } =
    useMerchantUsersQuery(
      merchant.id,
      pageInfo.pageNumber,
      pageInfo.pageSize,
    );

  const deleteUser = useDeleteMerchantUserMutation(
    merchant.id,
    () => {
      setUserToDelete(undefined);
      toast('Successfully removed user', { type: 'success' });
      refetchMerchantUsers();
    },
    (error: any) => {
      toast('An error occurred removing user', { type: 'error' });
    },
  );

  const { mutate: transferOwnership } = useTransferOwnershipMutation(
    merchant.id,
    () => {
      setShowConfirmTransfer(false);
      toast('Successfully transferred ownership', { type: 'success' });
      refetchMerchantUsers();
    },
    (error) => {
      toast('An error occurred transferring ownership', { type: 'error' });
      console.error('Failed to transfer ownership:', error);
    }
  );

  const handleTransferClick = (user: MerchantUser) => {
    setSelectedUserId(user.id);
    setUserToDelete(user);
    setShowConfirmTransfer(true);
  };

  const handleConfirmTransfer = () => {
    if (selectedUserId) {
      transferOwnership(selectedUserId);
    }
  };

  const columns: TableColumn<MerchantUser>[] = [
    {
      label: 'First Name',
      format: (val) => <>{val.user.firstName}</>,
    },
    {
      label: 'Last Name',
      format: (val) => <>{val.user.lastName}</>,
    },
    {
      className: 'hidden md:table-cell',
      label: 'Email',
      format: (val) => <>{val.user.email}</>,
    },
    {
      label: 'Actions',
      format: (val) => {
        const currentUserIsAdmin = merchantUsers?.data.find(mu => mu.user.id === user.id)?.role === 'MERCHANT_ADMIN';
        return (
          <>
            {val.role !== 'MERCHANT_ADMIN' && (
              <Button label="Remove" onClick={() => setUserToDelete(val)} />
            )}
            {currentUserIsAdmin && val.role !== 'MERCHANT_ADMIN' && (
              <Button
                label="Transfer Ownership"
                onClick={() => handleTransferClick(val)}
                variant="secondary"
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        open={userToDelete !== undefined}
        buttonLoading={deleteUser.status === 'pending'}
        explicitCloseButton={true}
        onClose={() => {
          setUserToDelete(undefined);
          setDeleteError(undefined);
        }}

        onButtonClick={() => userToDelete && deleteUser.mutate(userToDelete.id)}
        title="Remove User"
        buttonLabel="Remove"
      >
        <div className="py-4">
          {deleteError && <ErrorMessage message={[deleteError]} />}
        </div>
        <p>
          Are you sure you want to remove{` `}
          {userToDelete &&
            `${userToDelete.user.lastName}, ${userToDelete.user.firstName} from your merchant profile?`}
        </p>
      </Modal>

      <Modal
        open={showConfirmTransfer}
        onClose={() => {
          setShowConfirmTransfer(false);
          setSelectedUserId(null);
          setUserToDelete(undefined);
        }}
      >
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900">
            Confirm Transfer Ownership
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Are you sure you want to transfer ownership to{' '}
            {userToDelete && `${userToDelete.user.firstName} ${userToDelete.user.lastName}`}? 
            This action cannot be undone.
          </p>
          <div className="mt-4 flex justify-end space-x-2">
            <Button
              label="Cancel"
              onClick={() => setShowConfirmTransfer(false)}
              variant="secondary"
            />
            <Button
              label="Transfer"
              onClick={handleConfirmTransfer}
              variant="primary"
            />
          </div>
        </div>
      </Modal>

      <div>
        <div className="w-full">
          <Table
            loading={merchantUsersLoading}
            columns={columns}
            data={isSuccessMerchantUsers ? merchantUsers.data : []}
            pagination={{
              pageInfo: {
                pageNumber: parseInt(pageInfo.pageNumber as any) + 1,
                pageSize: parseInt(pageInfo.pageSize as any),
                totalSize: isSuccessMerchantUsers ? merchantUsers.totalSize : 0,
                hasNext: isSuccessMerchantUsers ? merchantUsers.hasNext : false,
                hasPrevious: isSuccessMerchantUsers ? merchantUsers.hasPrevious : false,
              },
              onChange: (info: PageInfo) => {
                setPageInfo({
                  pageNumber: info.pageNumber - 1,
                  pageSize: info.pageSize,
                });
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
