import { useCallback, useRef, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import useQuery from "../../../hooks/query-params.hook";
import { useNavigate } from "react-router";
import { ChevronRightIcon } from "@heroicons/react/outline";
import useCoupons from "../../../hooks/coupon.hook";
import useAuthContext from "../../../hooks/auth-context.hook";
import { CouponType } from "../../../models/coupon";
import { toast } from "react-toastify";
import DateRangePicker from "../../../common/library/DateRangePicker";
import BasicForm, { FormField } from "../../../common/library/BasicForm";
import ImagePicker from "../../../common/library/ImagePicker";
import Button from "../../../common/library/Button";
import dayjs from "dayjs";
import useContent from "../../../hooks/content.hook";

export default function CreateQbon() {
  const { merchant } = useAuthContext().getMerchantAndUser();

  const query = useQuery();
  const navigate = useNavigate();
  const errorDivRef = useRef(null);
  const couponType = query.get("type");

  const { useCreateCouponMutation, useCouponRedemptionMethodsQuery } =
    useCoupons();
  const { data: redemptionMethods } = useCouponRedemptionMethodsQuery(
    merchant.id
  );
  const createCoupon = useCreateCouponMutation(
    merchant.id,
    () => {
      setFile(undefined);
      setCouponData({
        name: "",
        description: "",
        type: couponType as CouponType,
        code: "",
        startDate: null,
        endDate: null,
        isSingleRedemption: true,
        pointsRequired: "20",
      });
      setFile(undefined);
      navigate("/dashboard/qbons");
      toast.success("Qbon created!");
    },
    (error) =>
      toast("An error occurred communicating with the server!", {
        type: "error",
      })
  );

  const { useUploadMerchantMediaMutation } = useContent();
  const uploadFile = useUploadMerchantMediaMutation(
    merchant.id,
    (res: { url: string }) => {
      executeCreateCoupon(res.url);
    },
    () => {
      toast("An error occurred uploading coupon cover image", {
        type: "error",
      });
    }
  );

  const [couponImageUrl, setCouponImageUrl] = useState<string>();
  const [file, setFile] = useState<File | undefined>();
  const [couponData, setCouponData] = useState<any>({
    name: "",
    description: "",
    type: couponType as CouponType,
    code: "",
    startDate: new Date(),
    endDate: dayjs(new Date()).add(30, "day").toDate(),
    discountType: null,
    discountAmount: null,
    redemptionMethod: null,
    isSingleRedemption: true,
  });

  const fields: FormField[] = [
    {
      label: "Name",
      required: true,
      value: couponData.name,
      onValueChange: (name) => {
        const newData = { ...couponData, name };
        setCouponData(newData);
      },
    },
    {
      label: "Description",
      required: true,
      value: couponData.description,
      onValueChange: (description) => {
        const newData = { ...couponData, description };
        setCouponData(newData);
      },
    },
    {
      label: "Redemption Method",
      value: (
        redemptionMethods.find(
          (r) => r.id === couponData.redemptionMethodId
        ) ?? { name: "Self redeem or scan" }
      ).name,
      combobox: true,
      options: redemptionMethods.map((method) => method.name),
      onValueChange: (value) => {
        if (value) {
          const id = (
            redemptionMethods.find((r) => r.name === value) ?? { id: 1 }
          ).id;
          const newData = { ...couponData, redemptionMethodId: id };
          setCouponData(newData);
        }
      },
    },
    {
      label: "Discount Type",
      value:
        couponData.discountType === null
          ? "Disabled"
          : couponData.discountType === "AMOUNT"
          ? "Amount ($)"
          : "Percentage (%)",
      combobox: true,
      options: ["Disabled", "Amount ($)", "Percentage (%)"],
      onValueChange: (value) => {
        if (value === "Disabled") {
          const newData = { ...couponData, discountType: null };
          setCouponData(newData);
        }
        if (value === "Amount ($)") {
          const newData = { ...couponData, discountType: "AMOUNT" };
          setCouponData(newData);
        }
        if (value === "Percentage (%)") {
          const newData = { ...couponData, discountType: "PERCENTAGE" };
          setCouponData(newData);
        }
      },
    },
    ...(couponData.discountType !== null
      ? [
          {
            label: "Discount Amount",
            value:
              couponData.discountAmount === null
                ? couponData.discountType === "PERCENTAGE"
                  ? `%1`
                  : 1
                : `${
                    couponData.discountType === "PERCENTAGE" ? "%" : "$"
                  }${parseFloat(couponData.discountAmount).toFixed(2)}`,
            onValueChange: (value: string) => {
              let input = value
                .replace("%", "")
                .replace("$", "")
                .replace(/[^0-9]/g, "");
              if (input.length > 10) {
                input = input.slice(0, 4);
              }
              while (input.length < 10) {
                input = "0" + input;
              }
              input =
                input.slice(0, input.length - 2) +
                "." +
                input.slice(input.length - 2);
              const val = parseFloat(input);

              setCouponData({
                ...couponData,
                discountAmount:
                  val > 100 && couponData.discountType === "PERCENTAGE"
                    ? 100
                    : val < 0 && couponData.discountType === "PERCENTAGE"
                    ? 1
                    : val > 1000000 && couponData.discountType === "AMOUNT"
                    ? 1000000
                    : val,
              });
            },
          } as FormField,
        ]
      : []),
    {
      label: "Discount Code",
      value: couponData.code,
      onValueChange: (code) => {
        const newData = { ...couponData, code };
        setCouponData(newData);
      },
    },
  ];

  const executeCreateCoupon = useCallback(
    (imageUrl?: string) => {
      createCoupon.mutate({
        ...couponData,
        status: "AVAILABLE",
        type: couponType as CouponType,
        file,
        startDate: couponData.startDate,
        endDate: couponData.endDate,
        redemptionMethodId:
          redemptionMethods.find(
            (code) => code.name === couponData.redemptionMethod
          )?.id || 1,
        couponImageUrl: imageUrl,
      });
    },
    [couponData, file, couponType, createCoupon, redemptionMethods]
  );

  const executeAction = useCallback(() => {
    if (couponData.name === "" || couponData.description === "") {
      toast("Name and description are required", { type: "error" });
      scrollToTop();
      return;
    }

    if (couponImageUrl && file) {
      uploadFile.mutate(file);
      return;
    }

    executeCreateCoupon();
  }, [
    executeCreateCoupon,
    couponData,
    file,
    couponImageUrl,
    uploadFile,
  ]);

  const message = useCallback(() => {
    if (
      couponData.startDate === undefined ||
      couponData.startDate === null ||
      couponData.startDate < new Date()
    ) {
      return (
        <div className="bg-yellow-50 p-6 rounded-lg mb-6">
          <div className="text-yellow-700 flex items-center gap-4">
            <ExclamationCircleIcon className="h-6 w-6" />
            <p>
              Coupons with no start date, start dates of today, or start dates
              before today will be active immediately
            </p>
          </div>
        </div>
      );
    }
  }, [couponData]);

  const scrollToTop = () => {
    if (errorDivRef.current) {
      (errorDivRef.current as any).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="max-w-4xl">
      <div className="flex items-center gap-2 mb-6">
        <div
          className="text-primary font-bold text-xl cursor-pointer underline text-[#fb3310]"
          onClick={() => navigate("/dashboard/qbons")}
        >
          Qbons
        </div>
        <ChevronRightIcon className="h-6 w-6 text-gray-500" />
        <h1 className="font-bold text-xl">Create Qbon</h1>
      </div>

      {message()}

      <div className="py-3">
        <BasicForm fields={fields} />

        <div className="w-full lg:w-[550px]">
          <div className="py-4">
            <h3 className="text-sm font-medium mb-2">Qbon Validity Period</h3>
            <DateRangePicker
              date={{ from: couponData.startDate, to: couponData.endDate }}
              onDateChange={(date) =>
                setCouponData({
                  ...couponData,
                  startDate: date?.from ?? new Date(),
                  endDate: date?.to ?? new Date(),
                })
              }
            />
          </div>
        </div>

        <div className="py-3">
          <div className="font-bold mb-2">
            Coupon Image {"(JPEG or PNG - 16:9 Aspect Ratio Suggested)"}
          </div>
          <div className="text-sm text-gray-600 mb-4">
            Give your coupon an image{" "}
            <span className="text-red-400">
              (if not set default will be used)
            </span>
          </div>

          <ImagePicker
            onComplete={(imageUrl, file) => {
              setCouponImageUrl(imageUrl);
              setFile(file);
            }}
            imageUrl={couponImageUrl}
            onRemove={() => {
              setCouponImageUrl(undefined);
              setFile(undefined);
            }}
          />
        </div>
      </div>

      <div className="pt-4">
        <Button
          className="w-full"
          label="Create Qbon"
          size="lg"
          disabled={createCoupon.status === "pending"}
          onClick={executeAction}
          loading={createCoupon.status === "pending"}
        />
      </div>
    </div>
  );
}
