import { useCallback, useRef, useState } from "react";
import useCoupons from "../../../hooks/coupon.hook";
import { ErrorMessage } from "../../../common/library/ErrorMessage";
import Button from "../../../common/library/Button";
import { Merchant } from "../../../models/merchant";
import { User } from "../../../models/users";
import TutorialDescriptionCard from "./TutorialDescriptionCard";
import dayjs from "dayjs";
import CreateQbonForm from "../merchant-raffles/CreateQbonForm";
import useContent from "../../../hooks/content.hook";

interface Props {
  merchant: Merchant;
  user: User;
  onComplete: () => void;
}

export default function CreateQbonTutorial({
  merchant,
  user,
  onComplete,
}: Props) {
  const errorDivRef = useRef(null);

  const { useCreateCouponMutation, useCouponRedemptionMethodsQuery } =
    useCoupons();
  const { data: redemptionMethods } = useCouponRedemptionMethodsQuery(
    merchant.id
  );
  const createCoupon = useCreateCouponMutation(
    merchant.id,
    () => {
      setFile(undefined);
      setCouponData({
        name: "",
        description: "",
        type: "PRIMARY",
        code: "",
        startDate: null,
        endDate: null,
        pointsRequired: "20",
      });
      setFile(undefined);
      setErrorMessage([]);
      onComplete();
    },
    () => setErrorMessage(["Please make sure to fill in name and description"])
  );

  const { useUploadMerchantMediaMutation } = useContent();
  const uploadFile = useUploadMerchantMediaMutation(
    merchant.id,
    (res) => {
      executeCreateCoupon(res.url);
    },
    () => {
      setErrorMessage(["An error occurred coupon cover image"]);
    }
  );

  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [couponData, setCouponData] = useState<any>({
    name: "",
    description: "",
    type: "PRIMARY",
    code: "",
    startDate: new Date(),
    endDate: dayjs().add(30, "day").toDate(),
    discountType: null,
    discountAmount: null,
    redemptionMethod: null,
  });

  const executeCreateCoupon = useCallback(
    (imageUrl?: string) => {
      createCoupon.mutate({
        ...couponData,
      status: "AVAILABLE",
      type: "SHARE",
      file,
      redemptionMethodId:
        redemptionMethods.find(
          (code) => code.name === couponData.redemptionMethod
        )?.id || 1,
      couponImageUrl: imageUrl,
      });
    },
    [createCoupon, couponData, file, redemptionMethods]
  );

  const executeAction = useCallback(() => {
    if (couponData.name === "" || couponData.description === "") {
      setErrorMessage(["Please make sure to fill in name and description"]);
      scrollToTop();
      return;
    }

    if (file) {
      uploadFile.mutate(file);
    } else {
      executeCreateCoupon();
    }
  }, [
    couponData,
    file,
    executeCreateCoupon,
    uploadFile,
  ]);

  const scrollToTop = () => {
    errorDivRef &&
      errorDivRef.current &&
      (errorDivRef.current as any).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <>
      <TutorialDescriptionCard
        title="Create Qbon"
        description="A Qbon is a discount you give consumers when they take a picture at your business and share it with you and their friends online."
        icon={
          <>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Plus Circle */}
              <circle
                cx="12"
                cy="12"
                r="10"
                fill="#FB3310"
                stroke="#4C1D95"
                strokeWidth="2"
              />

              {/* Plus Symbol */}
              <path
                d="M12 8V16M8 12H16"
                stroke="#7C3AED"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />

              {/* Decorative Dots */}
              <circle cx="12" cy="4" r="1" fill="#34D399" />
              <circle cx="20" cy="12" r="1" fill="#34D399" />
              <circle cx="12" cy="20" r="1" fill="#34D399" />
              <circle cx="4" cy="12" r="1" fill="#34D399" />
            </svg>
          </>
        }
      />
      <div className={"w-full"}>
        <div className="py-1" ref={errorDivRef}>
          <ErrorMessage message={errorMessage} />
        </div>

        <div className="py-3">
          <CreateQbonForm
            couponData={couponData}
            onChange={(data, file) => {
              setCouponData(data);
              setFile(file);
            }}
            merchant={merchant}
          />
        </div>

        <div className="pt-12">
          <Button
            label="Create Qbon"
            buttonWidth="full"
            loading={createCoupon.status === "pending"}
            onClick={executeAction}
          />
        </div>
        <div className="pt-3">
          <Button
            label="I'll create one later!"
            buttonWidth="full"
            variant="outline"
            loading={createCoupon.status === "pending"}
            onClick={onComplete}
          />
        </div>
      </div>
    </>
  );
}
