import { useFetch } from './fetch.hook';
import { useMutation } from '@tanstack/react-query';
import { MerchantUser } from '../models/merchant';

const Routes = {
  merchantUsers: (merchantId: number) => 
    `/merchants/${merchantId}/merchant-users`,
  merchantUser: (merchantId: number, merchantUserId: number) =>
    `/merchants/${merchantId}/merchant-users/${merchantUserId}`,
};

export default function useMerchantUsers() {
  const { fetchJsonFunc } = useFetch();

  const useTransferOwnershipMutation = (
    merchantId: number,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => {
    return useMutation({
      mutationFn: (merchantUserId: number) => fetchJsonFunc<MerchantUser>(
        Routes.merchantUser(merchantId, merchantUserId),
        {
          method: 'PUT',
        },
      ),
      onSuccess,
      onError,
    });
  };

  return {
    useTransferOwnershipMutation,
  };
} 