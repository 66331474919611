import useDevices from "../../../hooks/devices.hook";
import Table, { TableColumn } from "../../../common/library/Table";
import { QbonDevice } from "../../../models/devices";
import useAuthContext from "../../../hooks/auth-context.hook";
import Button from "../../../common/library/Button";
import { PlusIcon } from "@heroicons/react/solid";
import { Modal } from "../../../common/library/Modal";
import React, { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import Input from "../../../common/library/Input";
import Divider from "../../../common/library/Divider";
import InfoBox from "../../../common/InfoBox";
import { toast } from "react-toastify";

export default function MerchantDevices() {
  const { getMerchantAndUser } = useAuthContext();
  const [open, setOpen] = useState<boolean>(false);
  const [scannedId, setScannedId] = useState<string>();
  const [customId, setCustomId] = useState<string>("");
  const { merchant } = getMerchantAndUser();
  const {
    useMerchantDevicesQuery,
    useAssignMerchantDeviceMutation,
    useUnassignMerchantDevice,
  } = useDevices();
  const { data: devices, refetch } = useMerchantDevicesQuery(merchant.id);
  const { mutate: assignMerchantDevice } = useAssignMerchantDeviceMutation(
    merchant.id,
    () => {
      refetch();
      setScannedId(undefined);
      setCustomId("");
      setOpen(false);
      toast.success("Device assigned to merchant!");
    },
    (error) => {
      console.error(error);
      toast.error(error.message[0]);
    }
  );

  const { mutate: unassignDevice } = useUnassignMerchantDevice(
    merchant.id,
    () => {
      refetch();
      toast.info("Device removed from merchant");
    },
    () => {
      toast.error("Failed to unassign device");
    }
  );

  const columns: TableColumn<QbonDevice>[] = [
    {
      label: "ID",
      key: "uuid",
    },
    { className: "hidden sm:table-cell", label: "Created Date", key: "createdDate" },
    {
      label: "Actions",
      format: (device: QbonDevice) => {
        return (
          <>
            <Button
              variant={"outline"}
              label={"Remove Device"}
              onClick={() => {
                unassignDevice({ deviceId: device.uuid });
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="space-y-6">
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setScannedId(undefined);
          setCustomId("");
        }}
      >
        {!scannedId ? (
          <>
            <InfoBox
              message={
                "Scan the QR code on your device or type in the ID on the plate to add the device to your account."
              }
            />
            <QrScanner
              onResult={(result: any) => {
                const modifiedUrl = new URL(result);
                const deviceId = modifiedUrl.searchParams.get("openDevice");
                if (deviceId) {
                  setScannedId(deviceId);
                }
              }}
              onError={(error: any) => {
                console.error(error);
              }}
            />
            <Divider />
            <div className={"pt-3"}>
              <Input
                label={"Device ID"}
                value={customId}
                onChange={(val) => setCustomId(val)}
              />
            </div>
            <div className={"pt-3"}>
              <Button
                label={"Setup Device"}
                buttonWidth={"full"}
                variant={"outline"}
                disabled={customId === ""}
                onClick={() => setScannedId(customId)}
                icon={<PlusIcon />}
              />
            </div>
          </>
        ) : (
          <>
            <p>
              Would you like to assign this plate to your merchant? DEVICE_ID (
              {scannedId})
            </p>
            <div className={"flex py-6"}>
              <div className={"px-1"}>
                <Button
                  variant={"outline"}
                  onClick={() => assignMerchantDevice({ deviceId: scannedId })}
                  label={"Yes"}
                />
              </div>
              <div className={"px-1"}>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    setScannedId(undefined);
                    setOpen(false);
                  }}
                  label={"No"}
                />
              </div>
            </div>
          </>
        )}
      </Modal>

      <div className={"hidden md:block"}>
        <Button
          label={"Add Device"}
          icon={<PlusIcon />}
          onClick={() => setOpen(true)}
        />
      </div>
      <div className={"md:hidden"}>
        <Button
          label={"Add Device"}
          icon={<PlusIcon />}
          onClick={() => setOpen(true)}
          buttonWidth={"full"}
          alwaysShowLabel={true}
        />
      </div>
      <div>
        <Table columns={columns} data={devices ? devices.records : []} />
      </div>
    </div>
  );
}
