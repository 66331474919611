import { useEffect, useState } from "react";
import { PageInfo } from "../../../common/library/Pagination";
import Table, { TableColumn } from "../../../common/library/Table";
import useCoupons from "../../../hooks/coupon.hook";
import { Merchant } from "../../../models/merchant";
import { CouponClaim } from "../../../models/coupon";
import { toast } from "react-toastify";
import ClaimModal from "../../general/ClaimModal";

export default function CouponClaims({
  merchant,
  couponId,
}: {
  merchant: Merchant;
  couponId: string;
}) {
  const { useCouponClaimsQuery } = useCoupons();

  const [filter, setFilter] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 12,
  });
  const [selectedClaim, setSelectedClaim] = useState<CouponClaim | null>(null);

  const { data: claimsData, isFetching: isFetchingClaims, isSuccess } =
    useCouponClaimsQuery(merchant.id, parseInt(couponId || ""), filter.page, filter.pageSize);

  const columns: TableColumn<CouponClaim>[] = [
    {
      label: "User",
      format: (claim) =>
        `${claim.userProfile.firstName} ${claim.userProfile.lastName}`,
    },
    { label: "Email", format: (claim) => claim.userProfile.email },
    { label: "Claimed At", format: (claim) => new Date(claim.createdDate).toLocaleDateString() },
  ];

  useEffect(() => {
    if (selectedClaim && !selectedClaim.postId) {
      toast.error("Claim does not have an associated post!");
    }
  }, [selectedClaim]);

  return (
    <>
      {selectedClaim && selectedClaim.postId && (
        <ClaimModal
          claim={selectedClaim}
          merchantId={merchant.id}
          open={!!selectedClaim}
          onClose={() => setSelectedClaim(null)}
          postId={selectedClaim.postId}
        />
      )}
      <Table
        onRowClick={(row) => {
          setSelectedClaim(row);
        }}
        loading={isFetchingClaims}
        columns={columns}
        data={isSuccess ? claimsData.data : []}
        pagination={{
          pageInfo: {
            pageNumber: isSuccess
              ? claimsData.pageNumber + 1
              : 0,
            pageSize: isSuccess ? claimsData.pageSize : 0,
            totalSize: isSuccess ? claimsData.totalSize : 0,
            hasPrevious: isSuccess ? claimsData.hasPrevious : false,
            hasNext: isSuccess ? claimsData.hasNext : false,
          },
          onChange: (info: PageInfo) => {
            setFilter({
              page: info.pageNumber === 0 ? 0 : info.pageNumber - 1,
              pageSize: info.pageSize,
            });
          },
        }}
      />
    </>
  );
}
