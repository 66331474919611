import { Modal } from "../../common/library/Modal";
import useContent from "../../hooks/content.hook";
import { UserCouponProfile } from "../../models/arrival-logs";

interface Claim {
  userProfile: UserCouponProfile;
  createdDate: Date;
}

export type CouponClaimModalProps = {
  claim: Claim;
  postId: number;
  merchantId: number;
  open: boolean;
  onClose: () => void;
}

export default function ClaimModal({ claim, postId, merchantId, open, onClose }: CouponClaimModalProps) {
  const { useMerchantPostQuery } = useContent();

  const { data: postData } = useMerchantPostQuery(merchantId, postId);

  return (
    <Modal open={open} onClose={onClose} title="Claim Details">
      <div className="max-w-xl bg-white rounded-lg shadow-md overflow-hidden">
        {/* Post Header */}
        <div className="flex items-center p-4">
          <div className="h-10 w-10 rounded-full bg-gray-200 flex-shrink-0"></div>
          <div className="ml-3">
            <p className="font-semibold text-sm">{claim.userProfile.firstName} {claim.userProfile.lastName}</p>
            <p className="text-gray-500 text-xs">{new Date(claim.createdDate).toLocaleDateString()}</p>
          </div>
        </div>

        {/* Post Image */}
        {postData?.mediaUrl && (
          <div className="w-full">
            <img 
              src={postData.mediaUrl} 
              alt="Post content"
              className="w-full h-auto object-cover"
            />
          </div>
        )}

        {/* Post Content */}
        <div className="p-4">
          <p className="text-sm text-gray-800">{postData?.description}</p>
        </div>
      </div>
    </Modal>
  );
}
